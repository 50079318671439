<template>
  <app-alert color="primary" icon="$iconInfo">
    <div>{{ $t('unprocessed.isProcessed') }}</div>
    <app-link-btn
      class="mt-4 semi-bold"
      title="navigation.toVoucher"
      :to="getVoucherRoute(details)"
    />
  </app-alert>
</template>

<script>
export default {
  name: 'ProcessingFinishedAlert',

  props: {
    details: {
      type: Object,
      required: true
    }
  }
};
</script>
