<template>
  <div>
    <app-dialog
      v-model="open"
      primary-action-text="unprocessed.cancel.confirm"
      secondary-action-text="navigation.cancel"
      title="unprocessed.reupload.title"
      :loading="loading"
      @submit="initiateCancel"
    >
      <p>{{ $t('unprocessed.reupload.info') }}</p>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="unprocessed.reupload.startUpload"
      title="unprocessed.cancel.confirmed"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <p>{{ $t('unprocessed.reupload.startReupload') }}</p>
    </app-dialog>
  </div>
</template>

<script>
import { NAMESPACE } from '@/modules/Unprocessed/store';
import { mapActions } from 'vuex';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'CancelDialog',

  mixins: [ShowDialogMixin],

  computed: {
    voucherIdentifier() {
      return this.$route.params.id;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['executeCancel']),

    async initiateCancel() {
      this.loading = true;
      const { error } = await this.executeCancel(this.voucherIdentifier);
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
