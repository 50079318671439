<template>
  <div>
    <p class="text-h5 mb-1">{{ $t('debtors.invoiceDeliveryType') }}</p>
    <ValidationObserver v-if="isEditable" ref="form" tag="form">
      <v-row dense justify="space-between" align="center">
        <v-col cols="6">
          <app-select v-model="selectedDeliveryType" :items="selectItems" />
        </v-col>
      </v-row>
      <v-row dense class="mt-2">
        <v-col v-if="emailIsSelected" cols="8">
          <app-text-field
            v-model="newEmail"
            name="email"
            mode="lazy"
            rules="required|email"
            label="email"
          />
        </v-col>
        <v-col v-if="emailIsSelected" cols="12">
          <app-checkbox v-model="saveEmailAtLocation" label="unprocessed.saveEmailAtDebtor" />
        </v-col>
        <v-col v-if="emailIsSelected" cols="8" class="mt-2">
          <app-text-field
            v-model="newEmailCopy"
            name="emailCopy"
            mode="lazy"
            rules="email"
            label="unprocessed.mailCopy"
          />
        </v-col>
        <v-col v-if="emailIsSelected" cols="8" class="my-2">
          <app-text-field
            v-model="newEmailBlindCopy"
            name="emailBlindCopy"
            mode="lazy"
            rules="email"
            label="unprocessed.mailBlindCopy"
          />
        </v-col>
        <v-col
          v-if="
            deliveryTypeHasChanged ||
            deliveryEmailBlindCopyChanged ||
            deliveryEmailCopyChanged ||
            emailHasChanged ||
            saveEmailAtLocationHasChanged
          "
          cols="6"
        >
          <app-btn :loading="loading" class="save__button" @click="submitDeliveryType">
            {{ $t('navigation.take') }}
          </app-btn>
        </v-col>
      </v-row>
    </ValidationObserver>
    <span v-else>
      {{ selectionText }}
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Unprocessed/store';
import { DELIVERY_TYPES, DeliveryType } from '@/statics/deliveryType';

export default {
  name: 'DeliveryType',

  props: {
    email: {
      type: String,
      default: ''
    },
    emailCopy: {
      type: String,
      default: ''
    },
    emailBlindCopy: {
      type: String,
      default: ''
    },
    details: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    newEmail: '',
    newEmailCopy: '',
    newEmailBlindCopy: '',
    selectedDeliveryType: '',
    saveEmailAtLocation: false,
    loading: false
  }),

  computed: {
    selectItems() {
      return DELIVERY_TYPES.map((type) => ({
        label: this.$t(`addresses.deliveryTypes.${type}`),
        value: type
      }));
    },
    emailIsSelected() {
      return this.selectedDeliveryType === DeliveryType.EMAIL;
    },
    deliveryTypeHasChanged() {
      return this.selectedDeliveryType !== this.details.deliveryType;
    },

    deliveryEmailCopyChanged() {
      return this.newEmailCopy !== this.details.emailCopy;
    },

    deliveryEmailBlindCopyChanged() {
      return this.newEmailBlindCopy !== this.details.emailBlindCopy;
    },

    emailHasChanged() {
      return this.newEmail !== this.details.email;
    },
    saveEmailAtLocationHasChanged() {
      return this.saveEmailAtLocation !== this.details.emailMarkedForDebtor;
    },
    translatedDeliveryType() {
      return this.$t(`addresses.deliveryTypes.${this.selectedDeliveryType}`);
    },
    selectionText() {
      return this.emailIsSelected
        ? `${this.translatedDeliveryType} ${this.$t('addresses.to')}: ${this.details.email}`
        : this.translatedDeliveryType;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchUnprocessedList', 'editDeliveryType', 'editLocationEmail']),

    async submitDeliveryType() {
      this.loading = true;

      if (this.emailIsSelected && !(await this.$refs.form.validate())) {
        this.loading = false;
        return;
      }

      const { error } = await this.editDeliveryType({
        identifier: this.$route.params.id,
        addressData: {
          deliveryType: this.selectedDeliveryType,
          email: this.newEmail,
          emailCopy: this.newEmailCopy,
          emailBlindCopy: this.newEmailBlindCopy,
          emailMarkedForDebtor: this.saveEmailAtLocation
        }
      });

      if (error) {
        return;
      }

      if (this.emailNeedsSaveAtLocation()) {
        await this.submitLocationEmail();
      }

      await this.fetchUnprocessedList();

      if (this.$refs.form) {
        this.$refs.form?.reset();
      }
      this.loading = false;
    },

    submitLocationEmail() {
      return this.editLocationEmail({
        identifier: this.details.location.id,
        email: this.newEmail
      });
    },

    emailNeedsSaveAtLocation() {
      return (
        this.details.location &&
        this.details.location.email !== this.newEmail &&
        this.saveEmailAtLocation
      );
    }
  },

  created() {
    this.selectedDeliveryType = this.details.deliveryType;
    this.newEmail = this.details.email;
    this.newEmailCopy = this.details.emailCopy;
    this.newEmailBlindCopy = this.details.emailBlindCopy;
    this.saveEmailAtLocation = this.details.emailMarkedForDebtor;
  }
};
</script>

<style scoped>
.save__button {
  height: 56px;
}
</style>
