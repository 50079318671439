<template>
  <v-row v-if="!details.isProcessed" class="flex-nowrap flex-grow-0 my-0" align="center">
    <v-col cols="auto">
      <v-progress-circular :value="timerProgress" :rotate="270" size="44">
        <span v-if="time" class="overline text--muted">{{ time | formatTime }}</span>
        <v-icon v-if="!time && !hasStatusFailed(details)" class="icon--timer">
          mdi-timer-sand-empty
        </v-icon>
        <icon-alert v-if="!time && hasStatusFailed(details)" class="grey--text" />
      </v-progress-circular>
    </v-col>
    <v-col class="status__text">
      <template v-if="time">
        <div>{{ $t('unprocessed.deliveryInPreparation') }}</div>
        <i18n path="unprocessed.pauseDateInfo">
          <template #time>
            {{ time | formatTime }}
          </template>
        </i18n>
      </template>
      <template v-else>
        <span
          v-if="details.bnetStatus && $te(`bnetStatusOptions.${details.bnetStatus}.title`)"
          class="mr-3 d-flex align-center"
        >
          {{ details.bnetStatus | formatBnetStatus }}
          <app-tooltip
            v-if="$te(`bnetStatusOptions.${details.bnetStatus}.description`)"
            :text="`bnetStatusOptions.${details.bnetStatus}.description`"
          >
            <icon-info class="grey--text ml-2" height="15px" width="15px" />
          </app-tooltip>
        </span>
        <span v-else>{{ details.statusToShow | formatVoucherStatusToShow }}</span>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import StatusMixin from '@/mixins/StatusMixin';
import * as DELAY_TIMERS from '@/modules/Unprocessed/statics/processingDelays';

export default {
  name: 'StatusRow',

  mixins: [StatusMixin],

  props: {
    details: {
      type: Object,
      required: true
    },
    time: {
      type: Number,
      default: 0
    }
  },

  computed: {
    totalPauseInMs() {
      return this.isProduction ? DELAY_TIMERS.PAUSE_IN_MS_PROD : DELAY_TIMERS.PAUSE_IN_MS_DEV;
    },
    timerProgress() {
      return this.time ? 100 - (this.time * 100) / this.totalPauseInMs : 100;
    }
  }
};
</script>

<style scoped>
.v-progress-circular svg {
  width: 20px;
  height: 20px;
}

.icon--timer {
  opacity: 0.8;
  animation: spin 4s ease-in-out infinite forwards;
}

@keyframes spin {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.status__text {
  font-size: 14px;
}
</style>
