<template>
  <v-container class="pa-5 d-flex flex-column" style="max-width: 100%; height: 100%">
    <v-row no-gutters>
      <v-col cols="10">
        <h3 class="hide-overflow" style="max-width: 100%; white-space: nowrap">
          {{ details.originalName || $t('pdf') }}
        </h3>
      </v-col>
      <v-col
        v-if="filterItemsWithFulfilledCondition(menuActions).length > 0"
        cols="2"
        class="text-right"
      >
        <v-menu bottom left>
          <template #activator="{ on }">
            <app-icon-btn icon="mdi-dots-horizontal" v-on="on" />
          </template>

          <v-list>
            <v-list-item
              v-for="(action, index) in filterItemsWithFulfilledCondition(menuActions)"
              :key="index"
              :disabled="!action.callback || action.permission === false"
              @click="executeCallback(action.callback)"
            >
              <v-list-item-icon class="d-flex justify-center">
                <component
                  :is="action.icon"
                  style="height: 1rem"
                  :style="!action.callback ? 'fill: rgba(0,0,0,0.26)' : null"
                />
              </v-list-item-icon>
              <v-list-item-title>{{ action.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row no-gutters :class="isMobile ? 'mb-4' : 'mb-8'">
      <v-col cols="12" class="subtitle-1 semi-bold d-flex align-center flex-wrap">
        <icon-chevron-right height="10px" />
        <span class="mx-1">{{ details.type | formatVoucherType }}</span>
        <span v-if="details.parent" style="white-space: nowrap">
          {{ $t('unprocessed.forInvoice') }}
          <router-link :to="getVoucherRoute(details.parent)">
            {{ details.parent.numberToShow }}
          </router-link>
        </span>
      </v-col>
      <v-col v-if="hasAdminView" cols="12" class="subtitle-1 grey--text mt-0">
        <icon-chevron-right height="10px" />
        {{ $t('bnetIdentifier') }}: {{ details.bnetId || '-' }}
      </v-col>
    </v-row>

    <v-row v-if="details.isProcessed">
      <v-col class="alert__col">
        <processing-finished-alert :details="details" />
      </v-col>
    </v-row>

    <v-row v-else-if="hasStatusFailed(details)">
      <v-col class="alert__col">
        <bnet-error-alert :error-codes="details.bnetErrors" :actions="unprocessedActions" />
      </v-col>
    </v-row>

    <v-row v-else-if="hasStatusPending(details)">
      <v-col class="alert__col">
        <bnet-pending-alert :details="details" :actions="unprocessedActions" />
      </v-col>
    </v-row>

    <v-row v-if="isEncashmentVoucher">
      <v-col class="alert__col">
        <app-alert :value="isEncashmentVoucher" color="primary" icon="$iconInfo">
          <span v-html="$t('unprocessed.isEncashmentDirectVoucher')"></span>
        </app-alert>
      </v-col>
    </v-row>

    <v-row v-if="details.location" no-gutters class="body-1 my-4">
      <v-col cols="12" class="text-h5 mb-1">{{ $t('debtors.debtorInfo') }}</v-col>
      <v-col cols="12">
        <router-link :to="getLocationRoute(details.location)">
          {{ details.location.name }}
        </router-link>
      </v-col>
      <v-col v-if="details.location.address" cols="12">
        <template v-if="details.location.address.streetAndHouse">
          {{ details.location.address.streetAndHouse }},&#32;
        </template>
        {{ details.location.address.zip }} {{ details.location.address.city }}
      </v-col>
      <v-col v-if="details.location.email" cols="12">
        {{ $t('email') }}: {{ details.location.email }}
      </v-col>
    </v-row>

    <v-row
      v-if="permissions.unprocessedDetails.editDeliveryType && details.permissions.editDeliveryType"
    >
      <v-col cols="12">
        <delivery-type
          :details="details"
          :email="details.email"
          :email-copy="details.emailCopy"
          :email-blind-copy="details.emailBlindCopy"
          :is-editable="isEditable"
        />
      </v-col>
    </v-row>

    <v-row v-if="details.customisedLabel || isEditable" no-gutters class="body-1 my-4">
      <v-col cols="12">
        <customised-label
          :details="details"
          :customised-label="details.customisedLabel"
          :is-editable="isEditable"
        />
      </v-col>
    </v-row>

    <v-row v-if="isMobile" class="mt-2">
      <v-col>
        <file-link-btn highlighted prepend-icon title="navigation.view" @click="showPreview" />
      </v-col>
    </v-row>

    <v-row v-if="details.attachments.length > 0 || isEditable">
      <v-col cols="12">
        <attachment-upload
          :attachments="details.attachments"
          :is-editable="isEditable"
          @reload="fetchDetailedUnprocessed($route.params.id)"
        />
      </v-col>
    </v-row>

    <div v-if="hasAdminView">
      <app-api-log :data="detailedUnprocessedRaw" api-name="/voucher/show/${identifier}" />
      <app-api-log
        v-if="processLogRaw"
        :data="formattedProcessLog"
        :api-name="`Process-Log: ${$tc('resultsCounted', processLogRaw.length)}`"
      />
    </div>
  </v-container>
</template>

<script>
import AttachmentUpload from '@/modules/Unprocessed/components/Actions/AttachmentUpload';
import BnetErrorAlert from '@/modules/Unprocessed/components/Details/BnetErrorAlert';
import BnetPendingAlert from '@/modules/Unprocessed/components/Details/BnetPendingAlert';
import DeliveryType from '@/modules/Unprocessed/components/Details/DeliveryType';
import CustomisedLabel from '@/modules/Unprocessed/components/Details/CustomisedLabel';
import FileLinkBtn from '@/shared/components/FileLinkBtn';
import FormattedProcessLogMixin from '@/mixins/FormattedProcessLogMixin';
import History from '@/shared/components/History';
import ProcessingFinishedAlert from '@/modules/Unprocessed/components/Details/ProcessingFinishedAlert';
import PurchaseStatusAlert from '@/shared/components/PurchaseStatusAlert';
import StatusMixin from '@/mixins/StatusMixin';
import VoucherType from '@/statics/voucherType';
import { NAMESPACE } from '@/modules/Unprocessed/store';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'UnprocessedDetails',

  mixins: [StatusMixin, FormattedProcessLogMixin],

  components: {
    AttachmentUpload,
    BnetErrorAlert,
    BnetPendingAlert,
    DeliveryType,
    CustomisedLabel,
    FileLinkBtn,
    History,
    ProcessingFinishedAlert,
    PurchaseStatusAlert
  },

  data: () => ({
    activeTab: 0
  }),

  props: {
    details: {
      type: Object,
      default: () => ({})
    },
    isEditable: {
      type: Boolean,
      required: true,
      default: false
    },
    unprocessedActions: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(NAMESPACE, ['detailedUnprocessedRaw', 'processLogRaw']),
    menuActions() {
      return [this.queryParam.CANCEL].map((query) => this.unprocessedActions[query]);
    },
    isEncashmentVoucher() {
      return this.details.type === VoucherType.ENCASHMENT_INVOICE;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchDetailedUnprocessed']),

    executeCallback(callback) {
      if (callback === null) {
        return;
      }

      callback();
    },

    startAction(action) {
      this.unprocessedActions[action].callback();
    },

    showPreview() {
      this.setQueryParam(this.queryParam.PREVIEW, true);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-tab {
  justify-content: center !important;
}
::v-deep .row {
  flex-grow: 0;
  flex-shrink: 0;
}

.alert__col {
  margin-bottom: 16px;

  &:empty {
    margin-block: -12px;
  }
}
</style>
