<template>
  <div>
    <app-dialog
      v-model="open"
      primary-action-text="unprocessed.transferBasicProcessing.transfer"
      secondary-action-text="navigation.cancel"
      title="unprocessed.transferBasicProcessing.title"
      :loading="loading"
      @submit="onSubmit"
    >
      <p>{{ $t('unprocessed.transferBasicProcessing.info') }}</p>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.backToOverview"
      title="unprocessed.transferBasicProcessing.title"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <p>{{ $t('unprocessed.transferBasicProcessing.confirmedInfo') }}</p>
    </app-dialog>
  </div>
</template>

<script>
import { NAMESPACE } from '@/modules/Unprocessed/store';
import { mapActions } from 'vuex';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'TransferBasicProcessingDialog',

  data: () => ({
    newVoucherIdentifier: ''
  }),

  mixins: [ShowDialogMixin],

  methods: {
    ...mapActions(NAMESPACE, ['transferBasicProcessing']),

    async onSubmit() {
      this.loading = true;
      const { newVoucherIdentifier, error } = await this.transferBasicProcessing(
        this.$route.params.id
      );
      this.loading = false;

      if (error) {
        return;
      }

      this.newVoucherIdentifier = newVoucherIdentifier;
      this.submitted = true;
    },

    hideAfterSuccess() {
      this.submitted = false;
      this.$emit('success', this.newVoucherIdentifier);
      this.$emit('input', false);
    }
  }
};
</script>
