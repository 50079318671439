<template>
  <v-container mt-4 pa-0>
    <v-row no-gutters class="mb-2" align="center">
      <v-col cols="auto" class="text-h5">
        {{ $t('attachment') }}
      </v-col>
      <v-col v-if="isEditable" cols="auto">
        <app-tooltip :text="$tc('unprocessed.attachment.info', maxAttachmentsLength)">
          <icon-info class="ml-2 grey--text" height="15px" width="15px" />
        </app-tooltip>
      </v-col>
    </v-row>

    <v-row
      v-for="attachment in attachments"
      :key="attachment.id"
      align="baseline"
      class="mb-2"
      justify="space-between"
      no-gutters
    >
      <v-col cols="9">
        <file-link-btn
          :loading="loadingFileType === attachment.id"
          :title="attachment.fileName"
          prepend-icon
          @click="openFile(attachment.source, attachment.id, attachment.fileName)"
        />
      </v-col>
      <v-col cols="auto">
        <app-link-btn
          v-if="isEditable && permissions.unprocessedDetails.deleteAttachment"
          title="delete"
          variant="muted"
          @click="setQueryParam(queryParam.DELETE_ATTACHMENT, attachment.id)"
        />
      </v-col>
    </v-row>

    <v-card
      v-if="remainingUploadableAttachments > 0 && permissions.unprocessedDetails.addAttachment"
      flat
      class="mt-4 full-width"
    >
      <v-card-text class="body-1 pa-0">
        <app-dropzone
          v-model="newAttachments"
          :additional-form-data="{ voucherIdentifier: $route.params.id }"
          :has-empty-queue.sync="disableUpload"
          :url="url"
          :max-files="remainingUploadableAttachments"
          :start-upload="uploadingFiles"
          file-param="attachment"
          type="attachmentPl"
          select-files-text="unprocessed.attachment.select"
          @success="finishUpload"
          @error="uploadingFiles = false"
        />
      </v-card-text>
      <v-card-actions v-if="newAttachments.length > 0">
        <v-row>
          <v-col>
            <app-btn
              block
              :loading="loading"
              :disabled="disableUpload"
              @click="uploadingFiles = true"
            >
              {{ $t('upload.upload') }}
            </app-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <app-dialog
      :value="submitted"
      secondary-action-text="navigation.toVoucher"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <h3 slot="title" class="semi-bold">
        <v-icon style="font-size: 5rem" color="secondary">mdi-check-bold</v-icon>
      </h3>
      <p>{{ $t('unprocessed.attachment.uploadCompleted') }}</p>
    </app-dialog>

    <app-dialog
      :value="!!attachmentToDelete"
      :loading="loading"
      primary-action-text="navigation.yesDelete"
      secondary-action-text="navigation.cancel"
      title="unprocessed.attachment.confirmDelete"
      :text="
        $t('unprocessed.attachment.confirmDeleteInfo', {
          fileName: attachmentToDelete ? attachmentToDelete.fileName : ''
        })
      "
      @submit="executeDelete"
      @close="setQueryParam(queryParam.DELETE_ATTACHMENT, undefined)"
    />
  </v-container>
</template>

<script>
const MAX_ATTACHMENTS_LENGTH = 10;

import AppDropzone from '@/shared/components/AppDropzone';
import FileLinkBtn from '@/shared/components/FileLinkBtn';
import OpenFileMixin from '@/mixins/OpenFileMixin';
import { BASE_URL } from '@/plugins/axios';
import { NAMESPACE } from '@/modules/Unprocessed/store';
import { mapActions } from 'vuex';

export default {
  name: 'AttachmentUpload',

  mixins: [OpenFileMixin],

  components: {
    AppDropzone,
    FileLinkBtn
  },

  data: () => ({
    newAttachments: [],
    uploadingFiles: false,
    disableUpload: false,
    loading: false,
    submitted: false
  }),

  props: {
    attachments: {
      type: Array,
      default: () => []
    },
    isEditable: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    url() {
      return `${BASE_URL}/voucher/attachment-upload/`;
    },
    maxAttachmentsLength() {
      return MAX_ATTACHMENTS_LENGTH;
    },
    remainingUploadableAttachments() {
      return this.isEditable ? MAX_ATTACHMENTS_LENGTH - this.attachments.length : 0;
    },
    attachmentToDelete() {
      if (!this.permissions.unprocessedDetails.deleteAttachment) {
        return;
      }

      return this.attachments.find(
        (attachment) => attachment.id === this.getQueryParam(this.queryParam.DELETE_ATTACHMENT)
      );
    }
  },

  watch: {
    attachmentToDelete: {
      handler() {
        if (this.getQueryParam(this.queryParam.DELETE_ATTACHMENT) && !this.attachmentToDelete) {
          return this.setQueryParam(this.queryParam.DELETE_ATTACHMENT, undefined, true);
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['deleteAttachment']),

    finishUpload() {
      this.uploadingFiles = false;
      this.submitted = true;
    },

    hideAfterSuccess() {
      this.submitted = false;
      this.$emit('reload');
    },

    async executeDelete() {
      this.loading = true;
      const { error } = await this.deleteAttachment({
        voucherIdentifier: this.$route.params.id,
        attachmentIdentifier: this.getQueryParam(this.queryParam.DELETE_ATTACHMENT)
      });

      this.loading = false;
      if (error) {
        return;
      }

      this.setQueryParam(this.queryParam.DELETE_ATTACHMENT, undefined, true);
    }
  }
};
</script>
