<template>
  <app-filled-view-container @close="redirectToLastRoute">
    <template #actions>
      <copy-report-btn v-if="detailedUnprocessed" :custom-data="reportData" />
    </template>
    <template #list>
      <unprocessed-list :list="unprocessedList">
        <template #details>
          <div v-if="loadingDetails || !detailedUnprocessed" class="d-flex justify-center py-12">
            <app-spinner />
          </div>
          <template v-else>
            <unprocessed-details v-bind="unprocessedDetailsProps" />
          </template>
        </template>
      </unprocessed-list>
    </template>

    <template v-if="detailedUnprocessed" #details>
      <unprocessed-details v-bind="unprocessedDetailsProps" />
    </template>

    <template v-if="detailedUnprocessed" #preview>
      <v-row class="details__preview flex-column flex-nowrap ma-4 no-gutters">
        <v-col cols="auto">
          <status-row v-bind="statusRowProps" />
        </v-col>
        <v-col class="d-flex justify-center">
          <voucher-pdf-preview :voucher="detailedUnprocessed" />
        </v-col>
      </v-row>
    </template>

    <template v-if="unprocessedList.length === 0 || loadingDetails || detailsError" #content>
      <app-error-alert v-if="detailsError" />
      <app-spinner v-else-if="loadingDetails" />
      <div v-else class="text-center">
        <img
          :src="require('@/modules/Unprocessed/assets/EmptyUnprocessedList.svg')"
          :alt="$t('unprocessed.noDataTitle')"
        />
        <h3 class="mt-6">{{ $t('unprocessed.noDataTitle') }}</h3>
        <p class="mt-3">{{ $t('unprocessed.noDataText') }}</p>
      </div>
    </template>
    <template v-if="loadingList || listError" #container>
      <v-col cols="12" class="d-flex justify-center align-center">
        <app-error-alert v-if="listError" />
        <app-spinner v-else />
      </v-col>
    </template>

    <template v-if="!loadingList && !loadingDetails && !detailsError" #dialogs>
      <component
        :is="selectedAction.component"
        v-if="detailedUnprocessed"
        v-model="showActionDialog"
        :voucher="detailedUnprocessed"
        @success="selectedAction.onSuccess"
      />

      <voucher-preview-dialog
        v-if="isMobile"
        v-model="showVoucherPreview"
        :voucher="detailedUnprocessed"
      >
        <template #prepend-inner>
          <status-row v-bind="statusRowProps" />
        </template>
      </voucher-preview-dialog>
    </template>
  </app-filled-view-container>
</template>

<script>
import { NAMESPACE } from '@/modules/Unprocessed/store';
import { mapMutations, mapActions, mapState } from 'vuex';
import VoucherSubStatus from '@/statics/voucherSubStatus';
import ActionMixin from '@/mixins/ActionMixin';
import CancelDialog from '@/modules/Unprocessed/components/Actions/CancelDialog';
import ContinueDialog from '@/modules/Unprocessed/components/Actions/ContinueDialog';
import CopyReportBtn from '@/shared/components/CopyReportBtn';
import OpenFileMixin from '@/mixins/OpenFileMixin';
import RedirectMixin from '@/mixins/RedirectMixin';
import ReuploadDialog from '@/modules/Unprocessed/components/Actions/ReuploadDialog';
import StatusRow from '@/modules/Unprocessed/components/Details/StatusRow';
import TransferBasicProcessingDialog from '@/modules/Unprocessed/components/Actions/TransferBasicProcessingDialog';
import UnprocessedDetails from '@/modules/Unprocessed/components/Details/UnprocessedDetails';
import UnprocessedList from '@/modules/Unprocessed/components/UnprocessedList';
import VoucherPdfPreview from '@/shared/components/VoucherPdfPreview';
import VoucherPreviewDialog from '@/shared/components/VoucherPreviewDialog';

export default {
  name: 'Unprocessed',

  mixins: [ActionMixin, OpenFileMixin, RedirectMixin],

  data: () => ({
    remainingTimeDetailedIsEditable: null,
    timerDetailedEditable: null
  }),

  components: {
    CancelDialog,
    ContinueDialog,
    CopyReportBtn,
    ReuploadDialog,
    StatusRow,
    UnprocessedDetails,
    UnprocessedList,
    VoucherPdfPreview,
    VoucherPreviewDialog
  },

  computed: {
    ...mapState(NAMESPACE, [
      'unprocessedList',
      'detailedUnprocessed',
      'loadingList',
      'loadingDetails',
      'listError',
      'detailsError'
    ]),
    actions() {
      return {
        [this.queryParam.CANCEL]: {
          param: this.queryParam.CANCEL,
          component: CancelDialog,
          icon: 'icon-trash-can',
          text: this.$t('delete'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.CANCEL),
          permission: this.detailedUnprocessed.permissions.cancel,
          condition: this.permissions.unprocessedDetails.cancel,
          onSuccess: this.redirectToNewVoucher
        },
        [this.queryParam.REUPLOAD]: {
          param: this.queryParam.REUPLOAD,
          component: ReuploadDialog,
          text: this.$t('unprocessed.reupload.title'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.REUPLOAD),
          permission: this.detailedUnprocessed.permissions.cancel,
          condition: this.permissions.unprocessedDetails.reupload,
          onSuccess: this.redirectToUpload
        },
        [this.queryParam.CONTINUE]: {
          param: this.queryParam.CONTINUE,
          component: ContinueDialog,
          text: this.$t('unprocessed.continue.continueProcessing'),
          callback: () => this.setQueryParam(this.queryParam.ACTION, this.queryParam.CONTINUE),
          permission:
            this.detailedUnprocessed.permissions.continue &&
            [
              VoucherSubStatus.PENDING_DUPLICATE,
              VoucherSubStatus.PENDING_IN_FUTURE_BILLING,
              VoucherSubStatus.PENDING_IN_FUTURE_FACTORING,
              VoucherSubStatus.PENDING_NOT_PURCHASABLE,
              VoucherSubStatus.PENDING_INSURANCE_INVOICE_INCOMPLETE,
              VoucherSubStatus.PENDING_INSURANCE_PAYMENT_DEADLINE_TO_LONG,
              VoucherSubStatus.PENDING_INSURANCE_PAYMENT_DEADLINE_TO_SHORT,
              VoucherSubStatus.PENDING_INSURANCE_SERVICE_DATE_TO_OLD,
              VoucherSubStatus.PENDING_IN_FUTURE_PERFORMANCE_DATE
            ].includes(this.detailedUnprocessed.pendingStatus),
          condition: this.permissions.unprocessedDetails.continue,
          onSuccess: this.fetchUnprocessedList
        },
        [this.queryParam.TRANSFER_BASIC_PROCESSING]: {
          param: this.queryParam.TRANSFER_BASIC_PROCESSING,
          component: TransferBasicProcessingDialog,
          text: this.$t('unprocessed.transferBasicProcessing.transfer'),
          callback: () =>
            this.setQueryParam(this.queryParam.ACTION, this.queryParam.TRANSFER_BASIC_PROCESSING),
          condition: this.permissions.unprocessedDetails.continue,
          permission:
            this.detailedUnprocessed.permissions.continue &&
            this.detailedUnprocessed.pendingStatus === VoucherSubStatus.PENDING_ENCASHMENT_DIRECT,
          onSuccess: this.redirectToNewVoucher
        }
      };
    },
    showVoucherPreview: {
      get() {
        return this.getQueryParam(this.queryParam.PREVIEW);
      },
      set(value) {
        this.setQueryParam(this.queryParam.PREVIEW, value);
      }
    },
    pauseDateExpired() {
      return this.remainingTimeDetailedIsEditable === 0;
    },
    unprocessedDetailsProps() {
      return {
        key: this.pauseDateExpired,
        details: this.detailedUnprocessed,
        isEditable: !this.pauseDateExpired,
        unprocessedActions: this.actions
      };
    },
    statusRowProps() {
      return {
        key: this.remainingTimeDetailedIsEditable,
        details: this.detailedUnprocessed,
        time: this.remainingTimeDetailedIsEditable
      };
    },
    selectedAction() {
      return this.getSelectedAction(Object.values(this.actions));
    },
    reportData() {
      if (!this.detailedUnprocessed) {
        return;
      }

      return `Belegdaten:
        * Identifier: ${this.detailedUnprocessed.id}
        * bnet-Identifier: ${this.detailedUnprocessed.bnetId || '-'}`;
    }
  },

  methods: {
    ...mapMutations(NAMESPACE, { resetDetailedUnprocessed: 'RESET_DETAILED_UNPROCESSED' }),

    ...mapActions(NAMESPACE, ['fetchUnprocessedList', 'fetchDetailedUnprocessed']),

    async updateDetailedUnprocessed() {
      if (
        this.$route.name !== this.routeName.UNPROCESSED ||
        (!this.$route.params.id && this.isMobile)
      ) {
        return;
      }

      if (this.unprocessedList.length === 0 && !this.$route.params.id) {
        return;
      }

      if (!this.$route.params.id) {
        return this.$router.replace({
          params: { id: this.unprocessedList[0]?.id }
        });
      }

      await this.fetchDetailedUnprocessed(this.$route.params.id);

      if (!this.detailedUnprocessed) {
        return this.$router.replace({
          params: { id: this.unprocessedList[0]?.id }
        });
      }
      this.setTimer();
    },

    async redirectToNewVoucher(newVoucherIdentifier = undefined) {
      this.resetDetailedUnprocessed();
      await this.fetchUnprocessedList();

      this.$router.replace({
        params: {
          id:
            this.unprocessedList.length > 0 && !this.isMobile
              ? newVoucherIdentifier ?? this.unprocessedList[0].id
              : undefined
        },
        query: { ...this.$route.query }
      });
    },

    async redirectToUpload() {
      await this.redirectToNewVoucher();
      this.openVoucherUploadDialog();
    },

    setTimer() {
      clearInterval(this.timerDetailedEditable);
      this.remainingTimeDetailedIsEditable = null;

      if (!this.detailedUnprocessed) {
        return;
      }

      this.remainingTimeDetailedIsEditable = this.detailedUnprocessed.endOfPauseDate;
      if (this.pauseDateExpired || this.detailedUnprocessed.endOfPauseDate === null) {
        return;
      }

      this.timerDetailedEditable = setInterval(() => {
        this.remainingTimeDetailedIsEditable -= 1000;
      }, 1000);
    }
  },

  watch: {
    isMobile() {
      if (!this.isMobile && this.showVoucherPreview !== undefined) {
        this.showVoucherPreview = undefined;
      }

      if (!this.isMobile && !this.$route.params.id) {
        this.updateDetailedUnprocessed();
      }
    },

    '$route.params.id'() {
      this.updateDetailedUnprocessed();
    },

    'detailedUnprocessed.id'() {
      this.setTimer();
    },

    remainingTimeDetailedIsEditable() {
      if (this.remainingTimeDetailedIsEditable <= 0) {
        clearInterval(this.timerDetailedEditable);
      }
    }
  },

  async created() {
    const { error } = await this.fetchUnprocessedList();

    if (error || this.unprocessedList.length === 0) {
      return;
    }

    this.updateDetailedUnprocessed();
  },

  destroyed() {
    clearInterval(this.timerDetailedEditable);
    this.resetDetailedUnprocessed();
  }
};
</script>

<style scoped>
.details__preview {
  max-width: 600px;
  min-width: 0;
}
</style>
