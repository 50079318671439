<template>
  <app-alert :icon="errorCodes.length > 0 ? false : '$iconAlert'">
    <template v-if="errorCodes.length > 0">
      {{ $t('unprocessed.errorInfoCounted', { count: errorCodes.length }) }}
      <v-list class="mt-4" dense>
        <v-list-item v-for="(errorCode, index) in filteredErrorCodes" :key="index">
          <v-list-item-icon class="mr-4">
            <icon-alert class="error--text" />
          </v-list-item-icon>
          <v-list-item-content class="d-flex align-center semi-bold">
            {{ $t(`bnetStatusOptions.${errorCode}.title`) }}
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-row>
        <v-col v-if="actions[queryParam.CANCEL].condition !== false">
          <app-btn
            block
            outlined
            :disabled="actions[queryParam.CANCEL].permission === false"
            @click="actions[queryParam.CANCEL].callback()"
          >
            {{ $t('unprocessed.cancel.title') }}
          </app-btn>
        </v-col>
        <v-col v-if="actions[queryParam.REUPLOAD].condition !== false">
          <app-btn
            block
            :disabled="actions[queryParam.REUPLOAD].permission === false"
            @click="actions[queryParam.REUPLOAD].callback()"
          >
            {{ $t('unprocessed.reupload.title') }}
          </app-btn>
        </v-col>
      </v-row>
    </template>

    <template v-else>{{ $t('technicalError') }}</template>
  </app-alert>
</template>

<script>
export default {
  name: 'BnetErrorAlert',

  props: {
    errorCodes: {
      type: Array,
      required: true
    },
    actions: {
      type: Object,
      required: true
    }
  },

  computed: {
    filteredErrorCodes() {
      return this.errorCodes.filter((code) => this.$t(`bnetStatusOptions.${code}.title`) !== '');
    }
  }
};
</script>

<style scoped>
.v-list {
  background-color: unset;
}

.v-list-item {
  padding: 0;
}
</style>
