<template>
  <app-alert v-if="currentStatusConfig" :icon="false">
    <span class="caption">{{ $t('unprocessed.pending.info') }}</span>
    <v-list class="mt-4" dense>
      <v-list-item>
        <v-list-item-icon class="mr-4">
          <icon-alert class="error--text" />
        </v-list-item-icon>
        <v-list-item-content class="d-flex align-center semi-bold">
          {{
            $te(currentStatusConfig.reason)
              ? $t(currentStatusConfig.reason)
              : currentStatusConfig.reason
          }}
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <span class="mt-4">
      {{ $te(currentStatusConfig.text) ? $t(currentStatusConfig.text) : currentStatusConfig.text }}
    </span>

    <v-row class="mt-6">
      <v-col>
        <app-btn
          v-if="secondaryAction.condition !== false"
          :disabled="secondaryAction.permission === false"
          block
          outlined
          @click="secondaryAction.callback()"
        >
          {{ secondaryAction.text }}
        </app-btn>
      </v-col>
      <v-col>
        <app-btn
          v-if="primaryAction.condition !== false"
          :disabled="primaryAction.permission === false"
          block
          @click="primaryAction.callback()"
        >
          {{ primaryAction.text }}
        </app-btn>
      </v-col>
    </v-row>
  </app-alert>
</template>

<script>
import VoucherSubStatus from '@/statics/voucherSubStatus';

export default {
  name: 'BnetPendingAlert',

  props: {
    details: {
      type: Object,
      default: () => ({})
    },
    actions: {
      type: Object,
      required: true
    }
  },

  computed: {
    statusConfigs() {
      return [
        {
          status: VoucherSubStatus.PENDING_DUPLICATE,
          reason: 'unprocessed.pending.duplicate.reason',
          text: this.isFactoring
            ? 'unprocessed.pending.duplicate.textFactoring'
            : 'unprocessed.pending.duplicate.textBilling',
          primaryActionQuery: this.queryParam.CONTINUE,
          secondaryActionQuery: this.queryParam.CANCEL
        },
        {
          status: VoucherSubStatus.PENDING_NOT_PURCHASABLE,
          reason: this.$te(this.reasonTranslationKeyPath)
            ? `${this.$t('unprocessed.pending.notPurchasable.reason')} - ${this.$t(
                this.reasonTranslationKeyPath
              )}`
            : 'unprocessed.pending.notPurchasable.reason',
          text: 'unprocessed.pending.notPurchasable.text',
          primaryActionQuery: this.queryParam.CONTINUE,
          secondaryActionQuery: this.queryParam.CANCEL
        },
        {
          status: VoucherSubStatus.PENDING_IN_FUTURE_BILLING,
          reason: 'unprocessed.pending.inFutureBilling.reason',
          text: 'unprocessed.pending.inFutureBilling.text',
          primaryActionQuery: this.queryParam.CONTINUE,
          secondaryActionQuery: this.queryParam.CANCEL
        },
        {
          status: VoucherSubStatus.PENDING_IN_FUTURE_FACTORING,
          reason: 'unprocessed.pending.inFutureFactoring.reason',
          text: 'unprocessed.pending.inFutureFactoring.text',
          primaryActionQuery: this.queryParam.CONTINUE,
          secondaryActionQuery: this.queryParam.CANCEL
        },
        {
          status: VoucherSubStatus.PENDING_IN_FUTURE_PERFORMANCE_DATE,
          reason: 'unprocessed.pending.inFuturePerformanceDate.reason',
          text: 'unprocessed.pending.inFuturePerformanceDate.text',
          primaryActionQuery: this.queryParam.CONTINUE,
          secondaryActionQuery: this.queryParam.CANCEL
        },
        {
          status: VoucherSubStatus.PENDING_ENCASHMENT_DIRECT,
          reason: 'unprocessed.pending.encashmentDirect.reason',
          text: 'unprocessed.pending.encashmentDirect.text',
          primaryActionQuery: this.queryParam.TRANSFER_BASIC_PROCESSING,
          secondaryActionQuery: this.queryParam.CANCEL
        },
        {
          status: VoucherSubStatus.PENDING_INSURANCE_INVOICE_INCOMPLETE,
          reason: 'unprocessed.pending.insuranceInvoiceIncomplete.reason',
          text: 'unprocessed.pending.insuranceInvoiceIncomplete.text',
          primaryActionQuery: this.queryParam.CONTINUE,
          secondaryActionQuery: this.queryParam.CANCEL
        },
        {
          status: VoucherSubStatus.PENDING_INSURANCE_PAYMENT_DEADLINE_TO_SHORT,
          reason: 'unprocessed.pending.insurancePaymentDeadlineToShort.reason',
          text: 'unprocessed.pending.insurancePaymentDeadlineToShort.text',
          primaryActionQuery: this.queryParam.CONTINUE,
          secondaryActionQuery: this.queryParam.CANCEL
        },
        {
          status: VoucherSubStatus.PENDING_INSURANCE_PAYMENT_DEADLINE_TO_LONG,
          reason: 'unprocessed.pending.insurancePaymentDeadlineToLong.reason',
          text: 'unprocessed.pending.insurancePaymentDeadlineToLong.text',
          primaryActionQuery: this.queryParam.CONTINUE,
          secondaryActionQuery: this.queryParam.CANCEL
        },
        {
          status: VoucherSubStatus.PENDING_INSURANCE_SERVICE_DATE_TO_OLD,
          reason: 'unprocessed.pending.insuranceDateToOld.reason',
          text: 'unprocessed.pending.insuranceDateToOld.text',
          primaryActionQuery: this.queryParam.CONTINUE,
          secondaryActionQuery: this.queryParam.CANCEL
        }
      ];
    },
    currentStatusConfig() {
      return this.statusConfigs.find((item) => item.status === this.details.pendingStatus);
    },
    primaryAction() {
      return this.actions[this.currentStatusConfig.primaryActionQuery];
    },
    secondaryAction() {
      return this.actions[this.currentStatusConfig.secondaryActionQuery];
    },
    reasonTranslationKeyPath() {
      return `vouchers.bnetPurchaseStatusOptions.${this.details.bnetPurchaseStatus}`;
    }
  }
};
</script>

<style scoped>
.v-list {
  background-color: unset;
}

.v-list-item {
  padding: 0;
}
</style>
