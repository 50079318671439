<template>
  <div>
    <app-dialog
      v-model="open"
      primary-action-text="unprocessed.continue.continueProcessing"
      secondary-action-text="navigation.cancel"
      title="unprocessed.continue.title"
      :loading="loading"
      @submit="onSubmit"
    >
      <p>{{ $t('unprocessed.continue.info') }}</p>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.backToOverview"
      title="unprocessed.continue.confirmed"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <p>{{ $t('unprocessed.continue.confirmedInfo') }}</p>
    </app-dialog>
  </div>
</template>

<script>
import { NAMESPACE } from '@/modules/Unprocessed/store';
import { mapActions } from 'vuex';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'ContinueDialog',

  mixins: [ShowDialogMixin],

  methods: {
    ...mapActions(NAMESPACE, ['continueProcessing']),

    async onSubmit() {
      this.loading = true;
      const { error } = await this.continueProcessing(this.$route.params.id);
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
