<template>
  <app-list :list="formattedList" header="unprocessed.processing">
    <template #icon="{ item }">
      <icon-dot v-if="item.showError" />
      <icon-check v-else />
    </template>
    <template #overline="{ item }">
      <span v-if="hasStatusFailed(item) && $te(`bnetStatusOptions.${item.bnetStatus}.title`)">
        {{ $t('errorDetected') }}
      </span>
      <span v-else-if="hasStatusPending(item) && $te(`bnetStatusOptions.${item.bnetStatus}.title`)">
        {{ item.statusToShow | formatVoucherStatusToShow }}
      </span>
      <span v-else>
        {{ item.type | formatVoucherType }}
      </span>
    </template>
    <template #title="{ item }">
      {{ item.originalName || $t('pdf') }}
    </template>
    <template #subtitle="{ item }">
      <span v-if="item.bnetStatus && $te(`bnetStatusOptions.${item.bnetStatus}.title`)">
        {{ item.bnetStatus | formatBnetStatus }}
      </span>
      <span v-else>{{ item.statusToShow | formatVoucherStatusToShow }}</span>
    </template>
    <template #date="{ item }">
      <div class="flex-fill">{{ item.uploaded | formatDateTime }}</div>
      <div v-if="item.endOfPauseDate" class="flex-fill text-right">
        <v-icon size="12px">mdi-timer</v-icon>
        <timer :time="item.endOfPauseDate" />
      </div>
    </template>

    <template v-if="isMobile" #details>
      <slot name="details"></slot>
    </template>
  </app-list>
</template>

<script>
import StatusMixin from '@/mixins/StatusMixin';
import Timer from '@/modules/Unprocessed/components/Timer';

export default {
  name: 'UnprocessedList',

  mixins: [StatusMixin],

  props: {
    list: {
      type: Array,
      required: true
    }
  },

  components: {
    Timer
  },

  computed: {
    formattedList() {
      return this.list.map((item) => ({
        ...item,
        showError: this.hasStatusFailed(item) || this.hasStatusPending(item)
      }));
    }
  }
};
</script>
