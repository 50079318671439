var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-list',{attrs:{"list":_vm.formattedList,"header":"unprocessed.processing"},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var item = ref.item;
return [(item.showError)?_c('icon-dot'):_c('icon-check')]}},{key:"overline",fn:function(ref){
var item = ref.item;
return [(_vm.hasStatusFailed(item) && _vm.$te(("bnetStatusOptions." + (item.bnetStatus) + ".title")))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('errorDetected'))+" ")]):(_vm.hasStatusPending(item) && _vm.$te(("bnetStatusOptions." + (item.bnetStatus) + ".title")))?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatVoucherStatusToShow")(item.statusToShow))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatVoucherType")(item.type))+" ")])]}},{key:"title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.originalName || _vm.$t('pdf'))+" ")]}},{key:"subtitle",fn:function(ref){
var item = ref.item;
return [(item.bnetStatus && _vm.$te(("bnetStatusOptions." + (item.bnetStatus) + ".title")))?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatBnetStatus")(item.bnetStatus))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("formatVoucherStatusToShow")(item.statusToShow)))])]}},{key:"date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex-fill"},[_vm._v(_vm._s(_vm._f("formatDateTime")(item.uploaded)))]),(item.endOfPauseDate)?_c('div',{staticClass:"flex-fill text-right"},[_c('v-icon',{attrs:{"size":"12px"}},[_vm._v("mdi-timer")]),_c('timer',{attrs:{"time":item.endOfPauseDate}})],1):_vm._e()]}},(_vm.isMobile)?{key:"details",fn:function(){return [_vm._t("details")]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }