<template>
  <span>
    {{ remainingTimeInMs | formatTime }}
  </span>
</template>

<script>
export default {
  name: 'Timer',

  props: {
    time: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    remainingTimeInMs: 0,
    timer: null
  }),

  watch: {
    time() {
      if (this.remainingTimeInMs < 0) {
        clearInterval(this.timer);
      }
    }
  },

  created() {
    this.remainingTimeInMs = this.time;
    this.timer = setInterval(() => {
      this.remainingTimeInMs -= 1000;
    }, 1000);
  },

  destroyed() {
    clearInterval(this.timer);
  }
};
</script>
